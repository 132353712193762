<template>
  <div id="orderListBuyer" style="border: 0px solid #ddd; padding: 10px; background: white;" class="buyerodrlist">
    <div class="row">
      <div class="col-md-12 order-list-top">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h2 class="title">My Vouchers</h2>
          </div>
        </div>
      </div>
      <div id="table-content" class="col-md-12 col-sm-12 buyerord--list">
        <div id="table-content" class="col-sm-12" style="padding-right: 0px; padding-left: 0px;">
          <table id="example" class="table table-striped">
            <thead>
              <tr>
                <th scope="col" class="head-table">code</th>
                <th scope="col" class="head-table" style="text-align: center;">Voucher Type</th>
                <th scope="col" class="head-table">Voucher Status</th>
                <th scope="col" class="head-table" style="text-align: center;">Voucher Category</th>
                <th scope="col" class="head-table" style="text-align: center;">Amount</th>
                <th scope="col" class="head-table" style="text-align: center;">Min Order Value</th>
                <th scope="col" class="head-table" style="text-align: center;">Max Order Value</th>
                <th scope="col" class="head-table" style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
              <div class="col-md-12" id="preview-area" v-if="blockLoading">
                <div class="row text-center">
                  <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                  </div>
                </div>
              </div>
              <tr>
                <td
                  colspan="6"
                  class="text-center norec col-md-12"
                  v-if="blockLoading==false && tableItems.length==0"
                >No Records Found!</td>
              </tr>
              <tr v-for="(item, index) in tableItems" :key="index">
                <td class="text-center" style="vertical-align: middle;">
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <span style="font-size: 12px;">
                        <b>{{ item.code }}</b>
                      </span>
                      <br />
                    </div>
                  </div>
                </td>
                <td
                  class="text-center col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                >{{item.voucher_type === 1 ?  "One Time" : "Multiple"}}</td>
                <td
                  class="text-center col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                >{{ item.is_used ? "Received" : "Available" }}</td>
                 <td
                  class="text-center col-md-2"
                  style="vertical-align: middle; font-weight: 700;"
                >{{ item.dispute_user ===  undefined ? "Promotion Voucher ":"Dispute Voucher"}}</td>
                <td
                  class="text-center col-md-3"
                  style="vertical-align: middle; font-weight: 700;"
                >{{item.amount_type === 1 ? item.amount + "%" : "Rs:" + item.amount}}</td>
                <td
                  class="text-center col-md-3"
                  style="vertical-align: middle; font-weight: 700;"
                >{{item.min_value !== undefined ? "Rs:" + item.min_value : ""}}</td>
                <td
                  class="text-center col-md-3"
                  style="vertical-align: middle; font-weight: 700;"
                >{{item.max_value !== undefined ? "Rs:" + item.max_value : ""}}</td>
                <td class="text-center" style="vertical-align: middle;">
                  <button v-if="!item.is_used" class="btn btn-primary" @click="handdlecopy(item)"> Copy Voucher Code </button>
                </td>              
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row text-right" style="margin-bottom: -20px; margin-top: -20px;">
        <div class="col-sm-12">
          <div>
            <b-pagination
              v-model="currentPage"
              ref="paginationCurrentPage"
              :per-page="pageSize"
              :total-rows="rowcount"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import mixinOrder from "@/mixins/buyer/APIOrder";
export default {
  mixins: [mixinOrder],
  components: {},
  data() {
    return {
      breadcrumb: [
        {
          text: "My Vouchers",
          active: true
        }
      ],
      selectedArray: [],
      pageSize: 5,
      currentPage: 1,
      conditionCp: 1,
      rowcount: 0,
      tableItems: [],
      blockLoading: true,
    };
  },
  watch: {
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
        this.paginationStyleFunction(
          this.$refs.paginationCurrentPage.$refs.ul.children
        );
      }
    }
  },
  created() {
    this.handleBreadcrumb();
    this.getOrders();
  },
  mounted() {
    this.paginationStyleFunction(
      this.$refs.paginationCurrentPage.$refs.ul.children
    );
  },
  methods: {
    paginationStyleFunction(array) {
      if (array === "") {
        array = this.$refs.paginationCurrentPage.$refs.ul.children;
      }
      if (this.conditionCp !== this.currentPage) {
        for (let x = 0; x < array.length; x++) {
          if (array[x].innerText === this.conditionCp.toString()) {
            array[x].children[0].style.border = "";
            array[x].children[0].style.background = "";
            array[x].children[0].style.color = "black";
          }
        }
        this.conditionCp = this.currentPage;
      }
      for (let x = 0; x < array.length; x++) {
        if (array[x].innerText === this.currentPage.toString()) {
          array[x].children[0].style.border = "2px solid #337ab7";
          array[x].children[0].style.background = "#337ab7";
          array[x].children[0].style.color = "black";
        }
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getOrders: async function() {
      try {
        this.blockLoading = true;
        let responce = await this.getProfileVoucherList();
        this.rowcount = responce.length;
        this.tableItems = responce.reverse();
        // new Date(item.created_date* 1000).toGMTString()
        // this.tableItems.forEach(element => {
        //   element.date = new Date(element.created_date* 1000).toLocaleString()
        // });
        let sliceValue = this.currentPage * 5;
        sliceValue = sliceValue - 5;
        this.tableItems = this.tableItems.slice(sliceValue, sliceValue + 5);
        this.blockLoading = false;
      } catch (error) {
        throw error;
      }
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      this.currentPage = currentPage;
      this.getOrders();
    },
    handdlecopy(item) {
      this.$copyText(item.code);
      this.$swal.fire({
        position: "top-end",
        type: "success",
        title: "Copied!",
        showConfirmButton: false,
        timer: 800,
      });
    }
  }
};
</script>
<style>
#orderListBuyer {
  position: relative;
}
#orderListBuyer .vdp-datepicker input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  padding: 6px 12px;
}
#orderListBuyer .sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  z-index: 5;
}
#orderListBuyer .sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}
#orderListBuyer .sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color:#5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
#orderListBuyer .sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
#orderListBuyer .sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
#orderListBuyer .sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
#orderListBuyer .sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
#orderListBuyer .sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
#orderListBuyer .sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
#orderListBuyer .sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
#orderListBuyer .sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
#orderListBuyer .sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
#orderListBuyer .sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
#orderListBuyer .sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
#orderListBuyer .sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}
#orderListBuyer .order-list-top {
  padding-top: 10px;
}
#orderListBuyer .search-prd-block {
  text-align: right;
}
#orderListBuyer .search-prd-block input[type="text"] {
  display: inline-block;
  width: 50%;
}
#orderListBuyer .filter-block select {
  display: inline-block;
  width: 75%;
  height: 35px !important;
}
#orderListBuyer .filter-block button {
  float: right;
}
#orderListBuyer .from-to-block {
  padding-top: 15px;
}
#orderListBuyer .pagination {
  margin-right: 20px;
}
.buyerodrlist .buyerprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.buyerodrlist .buyerprof-sbtn:hover {
  color: #000000;
}
@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  /* top: 0; */
  left: 0;
  /* bottom: 60px; */
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.merchprof-send-to-admin-sbtn {
  background:#ffa300;
  color: white;
  border: none;
  margin-top: 5px;
}
.merchprof-send-to-admin-sbtn:hover {
  color: #000000;
}

@media screen and (max-width: 1348px) {
  table {
    width: 960px !important;
  }
  .img-thumbnail {
    max-width: 80px;
  }
}
@media screen and (max-width: 1275px) {
  .buyerord--list{
    max-width: 1000px;
    overflow-x: scroll;
  }
}
@media screen and (max-width: 1200px) {
  #orderListBuyer .search-prd-block input[type="text"] {
    width: 100%;
  }
  #orderListBuyer .filter-block select {
    width: 90%;
  }
}
@media screen and (max-width: 725px) {
  #orderListBuyer .filter-block select {
    width: 80%;
  }
}
@media screen and (max-width: 625px) {
  #orderListBuyer {
    border: none !important;
  }
}
@media screen and (max-width: 560px) {
  #orderListBuyer .from-block,
  .to-block {
    width: 100%;
  }
  #orderListBuyer .from-to-block .dp-adjust {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  #orderListBuyer .filter-block select {
    width: 70%;
  }
}
@media (max-width: 425px) {
  .nav-tabs > li > a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    margin-bottom: 6px;
    border: 1px solid #555;
    border-radius: 2px;
  }
  .nav-tabs > li {
    float: left;
    margin-bottom: -1px;
    margin-right: 4px;
  }
  .norec {
    text-align: left;
  }
}
</style>